import { components as OpenAPI } from "@/autogen/openapi";
import api from "@/lib/api/base";
import { Newsletter } from "@/types/newsletter";

export type SubscriptionState =
  | OpenAPI["schemas"]["SubscriberType"]
  | "not_subscribed"
  | "premium"
  | "pending_validation"
  | "pending_subscription"
  | "invalid_email"
  | "invalid_email__regex"
  | "invalid_subscription"
  | "errored_email"
  | "pending_email_address_confirmation"
  | "awaiting_email_address_confirmation";

export type SubscribeFormVariant =
  | "subscribe_page"
  | "archive_page"
  | "paywalled_archive_page";

// This should ideally match what's in `emails/middleware/utm.py`.
const RELEVANT_COOKIES = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

const decomposeCookieString = (
  cookieString: string
): { [key: string]: string } => {
  return Object.fromEntries(
    cookieString
      .split("; ")
      .map((x) => x.split(/=(.*)$/, 2).map(decodeURIComponent))
  );
};

const filterObjectByKeys = (
  object: { [key: string]: string },
  keys: string[]
) => {
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => keys.includes(key))
  );
};

const fetchRelevantCookies = (): { [key: string]: string } => {
  return filterObjectByKeys(
    decomposeCookieString(document.cookie),
    RELEVANT_COOKIES
  );
};

export const initializeAPI = async (newsletter: Newsletter) => {
  api.configure({
    baseUrl: "/v1",
    init: {
      headers: new Headers({
        "Content-Type": "application/json",
        // We're using the newsletter ID as the API key, because these are anonymous (i.e. not
        // authenticated) requests. This is handled by the `NewsletterIDKey` in
        // `app/api/views/subscribers/routes.py`.
        Authorization: `newsletter_id ${newsletter.id}`,
        "X-API-Version": "2024-08-15",
      }),
    },
  });
  return api;
};

export const subscribe = async (
  emailAddress: string,
  metadata: { [key: string]: string },
  newsletter: Newsletter,
  referring_subscriber_id: string | null
): Promise<{
  subscriber?: OpenAPI["schemas"]["Subscriber"] | null;
  error?: string | null;
}> => {
  const referralInformation = referring_subscriber_id
    ? { referring_subscriber_id: referring_subscriber_id }
    : {};

  const payload = {
    email_address: emailAddress,
    tags: new URLSearchParams(document.location.search).getAll("tag"),
    metadata,
    ...referralInformation,
    ...fetchRelevantCookies(),
    ...Object.fromEntries(new URLSearchParams(location.search)),
  };

  let subscriber: OpenAPI["schemas"]["Subscriber"];
  try {
    const api = await initializeAPI(newsletter);
    subscriber = (
      await api.path("/subscribers").method("post").create()(payload)
    ).data;
  } catch (e: any) {
    return { error: e.response?.data?.error };
  }

  // Handle analytics callbacks. This could probably be better abstracted,
  // but it's not causing any angst at the moment.
  if (window.plausible) {
    window.plausible("Subscribe");
  } else if (window.fathom && newsletter.fathom_subscribe_code) {
    window.fathom.trackGoal(newsletter.fathom_subscribe_code, 0);
  }
  return { subscriber };
};

export const mutableUrl = (
  newsletter: Newsletter,
  subscriber: OpenAPI["schemas"]["Subscriber"] | undefined,
  route: string
): string | null => {
  if (!subscriber) {
    return null;
  }
  if (newsletter.domain === "") {
    return `/${newsletter.username}/subscribers/${subscriber.id}${route}`;
  }

  return `/subscribers/${subscriber.id}${route}`;
};

// Given: `https://buttondown.com/archive/foo-bar-baz`
// Return: `foo-bar-baz`
export const extractSlug = (url: string): string => {
  if (url.endsWith("/")) {
    return url.toString().split("/").reverse()[1];
  }
  return url.toString().split("/").reverse()[0];
};
